import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'

import Layout from '../layouts'

function errorPage() {
  useEffect(() => {
    navigate('/')
  })

  return (
    <div>
      <Layout>
        <h1>Error page not found</h1>
        <Link to="/">Go back to home</Link>
      </Layout>
    </div>
  )
}

export default errorPage
